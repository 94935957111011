import { EnvEnum } from "@constants/enum";

export type FeatureToggleEnvType = Record<EnvEnum, boolean>;
export type FeatureToggleType = boolean | FeatureToggleEnvType;

export enum FeatureToggle {
  FeatureToggleDemo = "feature-toggle-demo",
  AIPlayground = "ai-playground",
  SmartDataTransform = "smart-data-transform",
  DamTools = "dam-tools",
  CategoryConfiguration = "category-configuration",
}

export const featureToggleConfig: Record<FeatureToggle, FeatureToggleType> = {
  [FeatureToggle.FeatureToggleDemo]: false,
  [FeatureToggle.SmartDataTransform]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.AIPlayground]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.DamTools]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.CategoryConfiguration]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
};
